import React from 'react';
import TitlePage from '../components/TitlePage';
import Img404 from '../images/404.png';

const NotFound = () => (
  <>
    <TitlePage text={"Page doesn't exist"} />
    <hr style={{ margin: `2rem 0` }} />

    <br />

    <div style={{display: "flex", justifyContent: "center" }}>
      <img src={Img404} alt="Page not found" />;
    </div>
  </>
);

export default NotFound;
